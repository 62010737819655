$default-detail-color: #e96c79;
$default-dark-color: #2b2b2b;
$bg-color: #fafafa;
$inputs-color: #929292;
$inputs-lighter-color: #d0d0d0;
$lightGray-color: #dbcdcd;
$medium-gray: #666666;
$success: #00b512;
$dark: #333333;
$bg-color-second: #f7f7f7;

@mixin baseColorSet($color: #e96c79) {
  background-color: transparentize($color, 0.9);
  border-color: $color;
}
