@use '@angular/material' as mat;
@import 'src/assets/styles/theming';

@include mat.core();

$front-takeaway-front-primary: mat.define-palette($mat-primary, main, lighter, darker);
$front-takeaway-front-accent: mat.define-palette($mat-accent, main, lighter, darker);

// The warn palette is optional (defaults to red).
$front-takeaway-warn: mat.define-palette($mat-warn, main, lighter, darker);

$my-theme: mat.define-light-theme((
  color: (
    primary: $front-takeaway-front-primary,
    accent: $front-takeaway-front-accent,
  ),
  density: 0,
));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);


@import 'assets/styles/colors.scss';
@import 'assets/styles/buttons';
@import 'assets/styles/inputs';
@import 'assets/styles/margins';
@import 'assets/styles/toast';
@import 'assets/styles/headers';
@import 'assets/styles/text';
@import 'assets/styles/animations';
@import 'assets/styles/paddings';
@import 'assets/styles/material';

html,
body {
  height: 100vh !important;
  width: auto;
  overflow-x: hidden;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  input[type='tel'] {
    font-size: 16px;
  }
}

body,
button {
  font-family: 'Roboto', sans-serif;
}

button {
  font-size: 15px;
}

textarea {
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4 {
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
}

* {
  box-sizing: border-box;
}

.container-20 {
  padding: 20px;
}

.container-padding {
  padding: 10px 20px 20px 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

button:focus {
  outline: 0;
}

.gdpr-info {
  font-size: 11px;
  opacity: 0.6;
  width: 75%;
  max-width: 350px;
  text-align: center;
  display: inline-block;
  line-height: 14px;
}

.bold-text {
  font-weight: 500;
  color: black;
}

app-layout-header {
  background: inherit;
}

a {
  //color: $default-detail-color;
  text-decoration: none;
}

//.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
//  background: yellow;
//}

.cdk-overlay-backdrop {
  overflow: hidden;
}
